import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { Flex } from '@rebass/grid'

import { FetchProvider } from '../components/AsyncValueProvider'
import { InputProvider } from '../components/InputProvider'
import AppStateProvider from '../components/AppStateProvider'
import Landing from '../components/Landing'
import Input from '../components/layout/Input'
import { ErrorText } from '../components/Error'

const JoinGroup = ({ match }) => (
  <AppStateProvider>
    {({
      facebook,
      songkick,
      spotify,
      appleMusic,
      showFestivals,
      loginWithAppleMusic,
      loginWithSpotify,
      confirmUsername,
      getProviderTokens,
      disconnectSpotify,
      loginWithFacebook,
      disconnectFacebook
    }) => (
      <InputProvider>
        {({ value: name, handleChange }) => (
          <FetchProvider
            getOnMount={false}
            url={`/group/${match.params.groupId}/join`}
            options={{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                ...getProviderTokens(),
                name
              })
            }}
          >
            {({
              value: joinGroupResult,
              loading: joiningGroup,
              error: joinGroupError,
              get: joinGroup
            }) => {
              if (joinGroupResult && joinGroupResult.success) {
                return <Redirect to={`/g/${match.params.groupId}`} />
              }
              return (
                <FetchProvider url={`/group/${match.params.groupId}/info`}>
                  {({ value: group, loading }) =>
                    loading ? null : (
                      <Landing
                        displayingFestivals={joiningGroup}
                        showFestivals={showFestivals}
                        loginWithSpotify={loginWithSpotify}
                        loginWithAppleMusic={loginWithAppleMusic}
                        spotify={spotify}
                        appleMusic={appleMusic}
                        songkick={songkick}
                        confirmUsername={confirmUsername}
                        disableOptions={name.length < 2}
                        description={
                          <Fragment>
                            <h3>Joining {group.ownerName}'s group</h3>
                            <p>
                              Enter your name and then choose a service to
                              connect with
                            </p>
                            <Flex mt={3} />
                            <Input
                              placeholder="Name"
                              value={name}
                              onChange={handleChange}
                            />
                            {joinGroupError && (
                              <ErrorText>
                                Something went wrong, please check that you
                                entered valid details
                              </ErrorText>
                            )}
                          </Fragment>
                        }
                        onConnect={joinGroup}
                      />
                    )
                  }
                </FetchProvider>
              )
            }}
          </FetchProvider>
        )}
      </InputProvider>
    )}
  </AppStateProvider>
)

export default JoinGroup
