import {
  LOGIN_URL as FACEBOOK_LOGIN_URL,
  getUsername as getFacebookUsername
} from './services/facebook'
import {
  LOGIN_URL as SPOTIFY_LOGIN_URL,
  getUsername as getSpotifyUsername
} from './services/spotify'

const FACEBOOK = 'facebook'
export const SPOTIFY = 'spotify'
export const APPLE_MUSIC = 'appleMusic'

const LS_KEYS = {
  [APPLE_MUSIC]: {
    TOKEN: 'amToken'
  },
  [SPOTIFY]: {
    TOKEN: 'spotifyToken',
    TOKEN_EXPIRES: 'spotifyTokenExpiry',
    USERNAME: 'spotifyUsername'
  },
  [FACEBOOK]: {
    TOKEN: 'facebookToken',
    TOKEN_EXPIRES: 'facebookTokenExpiry',
    USERNAME: 'facebookUsername'
  }
}

const waitForMusicKit = () => {
  return new Promise(resolve => {
    document.addEventListener('musickitloaded', function() {
      return resolve()
    })
  })
}

export const getAppleMusicInstance = async () => {
  if (!window.MusicKit) {
    await waitForMusicKit()
  }
  window.MusicKit.configure({
    developerToken: process.env.REACT_APP_APPLE_DEVELOPER_TOKEN,
    app: {
      name: 'Festival Finder',
      build: '1.0'
    }
  })

  return window.MusicKit.getInstance()
}

export const login = key => {
  return new Promise((resolve, reject) => {
    const loginUrl =
      key === SPOTIFY
        ? `${SPOTIFY_LOGIN_URL}&state=${encodeURIComponent(
            window.location.pathname
          )}`
        : FACEBOOK_LOGIN_URL
    const popup = window.open(
      loginUrl,
      '_blank',
      'width=600,height=600,toolbar=0,menubar=0,location=0,status=1,scrollbars=0,resizable=0,left=0,top=0'
    )

    const listener = setInterval(() => {
      if (popup) {
        popup.postMessage('login', window.location)
      } else {
        if (checkToken(key)) {
          resolve()
          window.onmessage = null
        }
      }
    }, 1000)

    window.onmessage = event => {
      const { token, expiry } = JSON.parse(event.data)
      storeToken(key, token, expiry)
      clearInterval(listener)
      window.onmessage = null
      return resolve(token)
    }
  })
}

export const logout = key => {
  localStorage.removeItem(LS_KEYS[key].TOKEN)
  localStorage.removeItem(LS_KEYS[key].TOKEN_EXPIRES)
}

export const getUsername = key => {
  if (key === SPOTIFY) {
    return getSpotifyUsername(getToken(key)).then(name => {
      storeName(key, name)
      return name
    })
  }
  if (key === FACEBOOK) {
    return getFacebookUsername(getToken(key)).then(name => {
      storeName(key, name)
      return name
    })
  }
}

export const getStoredName = key => {
  const storedName = localStorage.getItem(LS_KEYS[key].USERNAME)
  return storedName || ''
}

const storeName = (key, name) => {
  localStorage.setItem(LS_KEYS[key].USERNAME, name)
}

export const storeToken = (key, token, expiry = 3600) => {
  localStorage.setItem(LS_KEYS[key].TOKEN, token)
  localStorage.setItem(
    LS_KEYS[key].TOKEN_EXPIRES,
    new Date().getTime() + expiry * 1000
  )
}

export const getToken = key => {
  const token = localStorage.getItem(LS_KEYS[key].TOKEN)
  return token
}

export const checkAppleMusicToken = async () => {
  const appleMusic = await getAppleMusicInstance()
  return appleMusic.isAuthorized
}
export const checkToken = key => {
  const token = localStorage.getItem(LS_KEYS[key].TOKEN)
  const expiry = localStorage.getItem(LS_KEYS[key].TOKEN_EXPIRES)

  if (!token || !expiry) {
    return false
  }

  const timeNow = new Date().getTime()
  const expired = expiry - timeNow < 1000 * 60 * 5
  if (expired) {
    return false
  }

  return true
}

export const getStoredNames = () => {
  const object = {}
  const spotifyName = getStoredName(SPOTIFY)
  const facebookName = getStoredName(FACEBOOK)
  if (spotifyName) {
    object[SPOTIFY] = spotifyName
  }
  if (facebookName) {
    object[FACEBOOK] = facebookName
  }
  return object
}

export const getProviderTokens = () => {
  const object = {
    appleMusicDeveloperToken: process.env.REACT_APP_APPLE_DEVELOPER_TOKEN
  }
  if (checkAppleMusicToken()) {
    object[APPLE_MUSIC] = getToken(APPLE_MUSIC)
  }
  if (checkToken(SPOTIFY)) {
    object[SPOTIFY] = getToken(SPOTIFY)
  }
  if (checkToken(FACEBOOK)) {
    object[FACEBOOK] = getToken(FACEBOOK)
  }
  return object
}
