import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Flex } from '@rebass/grid'

import { UsernameModalWithState } from '../UsernameModal'

import {
  Container,
  Title,
  Year,
  YearContainer,
  Option,
  Description,
  FirstStep
} from './style'
import { Loading } from '../Loading'

class Landing extends Component {
  componentDidMount() {
    const {
      location: { state },
      go,
      onConnect
    } = this.props

    if (state && state.go) {
      const goFunction = onConnect || go
      goFunction()
    }
  }

  render() {
    const {
      displayingFestivals,
      showFestivals,
      loginWithSpotify,
      spotify,
      description,
      onConnect,
      titleOnly,
      extraContent,
      loginWithAppleMusic,
      confirmUsername,
      disableOptions
    } = this.props
    return (
      <Container displayingFestivals={displayingFestivals}>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          px={[3, 5]}
        >
          <Flex
            style={{ width: '100%' }}
            justifyContent="space-between"
            flexDirection={['column', 'row']}
          >
            <Flex flexDirection="column">
              <Title>
                Festival <br /> Finder 🎪
              </Title>
            </Flex>
            {showFestivals && !displayingFestivals ? (
              <Flex pt={3} justifyContent="center" alignItems="flex-end">
                <Loading />
              </Flex>
            ) : (
              extraContent
            )}
          </Flex>
          {!titleOnly && (
            <FirstStep pose={showFestivals ? 'hide' : 'show'}>
              <Description>
                {description ||
                  "Connect with a platform below to find you and your friends' best festival this year"}
              </Description>
              <Flex
                mt={5}
                flexDirection="column-reverse"
                alignItems="flex-start"
              >
                <Flex flexDirection="column" alignItems="flex-start">
                  <UsernameModalWithState
                    platform="songkick"
                    displayPlatform="Songkick"
                    confirmUsername={confirmUsername(onConnect)}
                  >
                    {({ toggle }) => (
                      <Option
                        smaller
                        disabled={disableOptions}
                        onClick={toggle}
                      >
                        <span>Songkick</span>
                      </Option>
                    )}
                  </UsernameModalWithState>
                  <Flex mt={3} />
                  <UsernameModalWithState
                    platform="lastfm"
                    displayPlatform="Last.fm"
                    confirmUsername={confirmUsername(onConnect)}
                  >
                    {({ toggle }) => (
                      <Option
                        smaller
                        disabled={disableOptions}
                        onClick={toggle}
                      >
                        <span>Last.fm</span>
                      </Option>
                    )}
                  </UsernameModalWithState>
                </Flex>
                <Flex mb={4} />
                <Option
                  disabled={disableOptions}
                  onClick={loginWithAppleMusic(onConnect)}
                >
                  <span>Apple Music</span>
                </Option>
                <Flex mb={4} />
                <Option
                  disabled={disableOptions}
                  onClick={loginWithSpotify(onConnect)}
                >
                  <span>{spotify.loggingIn ? 'Connecting...' : 'Spotify'}</span>
                </Option>
              </Flex>
            </FirstStep>
          )}
        </Flex>
      </Container>
    )
  }
}

export default withRouter(Landing)
