import styled from 'styled-components'
import { selectors as s } from '../styles'

export const Text = styled.span`
  ${({ family }) => `font-family: ${s['font' + family]}`};
  ${({ size }) => `font-size: ${s[`fs${size}`]}`};
  letter-spacing: ${({ theme, spacing }) =>
    spacing ? theme[`ls${spacing}`] : 0};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`

Text.defaultProps = {
  family: 'Family',
  size: 4
}

export const Title = styled.h3`
  margin-bottom: ${s.ss2};
  font-family: ${s.fontHeading};
`
