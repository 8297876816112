import React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom'
import { FaUsers } from 'react-icons/fa'
import { Flex } from '@rebass/grid'

import Input, { InputLabel, InputInfo } from './layout/Input'
import Modal, { ModalTitle } from './layout/Modal'
import Button from './Button'
import { FetchProvider } from './AsyncValueProvider'
import { InviteModalContent } from './InviteGroupModal'

import OpenCloseState from './OpenCloseState'

class CreateGroupModal extends Component {
  state = { name: '' }

  handleName = e => this.setState({ name: e.target.value })

  render() {
    const { name } = this.state
    const {
      getProviderTokens,
      onCreateGroupId,
      createdGroupId,
      onDismiss,
      toggle
    } = this.props
    const providers = getProviderTokens()

    const requiresName = !Object.keys(providers).includes('spotify')
    const buttonIsValid = requiresName ? name.length > 1 : true

    return (
      <FetchProvider
        getOnMount={false}
        after={({ value: { groupId, ownerName } }) =>
          onCreateGroupId(groupId, ownerName)
        }
        url={`/create-group/`}
        options={{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...providers,
            name
          })
        }}
      >
        {({ value, loading, get }) => (
          <Modal
            {...this.props}
            toggle={
              value && value.groupId ? () => onDismiss(value.groupId) : toggle
            }
          >
            <Flex flexDirection="column" alignItems="center">
              {loading ? (
                <ModalTitle>Creating...</ModalTitle>
              ) : (value && value.groupId) || createdGroupId ? (
                <Fragment>
                  <ModalTitle>Group Created</ModalTitle>
                  <InviteModalContent
                    url={`${window.location.origin}/g/${createdGroupId ||
                      value.groupId}/join`}
                  />
                  <Flex mt={3} />
                  <Link to={`/g/${createdGroupId || value.groupId}`}>
                    <Button color="black">Go to Group</Button>
                  </Link>
                </Fragment>
              ) : (
                <Fragment>
                  <ModalTitle>Create a Group</ModalTitle>
                  <p>
                    Create a group to find the best festival for your group of
                    friends. It's as simple as sharing a link with up to 8
                    buddies. Get started by clicking the button below to
                    generate your share link.
                  </p>
                  {requiresName && (
                    <Flex
                      style={{ width: '100%' }}
                      mt={3}
                      flexDirection="column"
                    >
                      <InputLabel>Enter your name</InputLabel>
                      <Input value={name} onChange={this.handleName} />
                      <InputInfo>
                        Required to see who's who in the results
                      </InputInfo>
                    </Flex>
                  )}
                  <Flex mt={3} />
                  <Button
                    disabled={!buttonIsValid}
                    color="black"
                    onClick={
                      !value && !loading && buttonIsValid ? get : undefined
                    }
                  >
                    {loading ? 'Loading' : 'Generate Share Link'}
                  </Button>
                </Fragment>
              )}
            </Flex>
          </Modal>
        )}
      </FetchProvider>
    )
  }
}

export const CreateGroupModalButton = ({
  getProviderTokens,
  onCreateGroupId,
  createdGroupId,
  onDismiss
}) => (
  <OpenCloseState>
    {({ isOpen, toggle }) => (
      <Fragment>
        <Button fadeIn onClick={toggle} color="black">
          <Flex alignItems="center">
            <FaUsers /> <span>Create a Group</span>
          </Flex>
        </Button>
        <CreateGroupModal
          onDismiss={onDismiss}
          getProviderTokens={getProviderTokens}
          active={isOpen}
          toggle={toggle}
          onCreateGroupId={onCreateGroupId}
          createdGroupId={createdGroupId}
        />
      </Fragment>
    )}
  </OpenCloseState>
)

export default CreateGroupModal
