import styled, { css } from 'styled-components'

import { selectors as s, letterSpacing } from '../styles'

export const SelectablePill = styled.span`
  ${letterSpacing};
  padding: ${s.ss1} ${s.ss2};
  background: rgba(255, 255, 255, 0.3);
  border-radius: ${s.rrSmall};
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  &:hover {
    background: rgba(255, 255, 255, 0.6);
  }
  ${({ selected }) =>
    selected &&
    css`
      background: ${s.offWhite};
      color: ${s.primary};
    `};
`
