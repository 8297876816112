import styled, { css } from 'styled-components'
import posed from 'react-pose'
import { Flex } from '@rebass/grid'

import { selectors as s, media as m } from '../../styles'

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'flex-start'
})`
  padding-top: 80px;
  padding-bottom: ${({ displayingFestivals }) =>
    displayingFestivals ? s.ss3 : 0};
  ${m.tablet`
    padding-top: 10vh;
  `};
  ${m.xlarge`
    padding-top: 20vh;
  `};
`
export const GoButton = styled.div``
export const InnerContainer = styled.div``
export const Placeholder = styled.div``

export const OptionsWrapper = styled(Flex).attrs({ flexDirection: 'column' })``

export const Instructions = styled.div`
  font-size: ${s.fs4};
  max-width: 350px;
  text-align: justify;
  color: ${s.gray};
  h4 {
    display: inline-block;
    border-bottom: 3px solid white;
    padding-bottom: 4px;
  }
`

const PosedFirstStep = posed.div({
  show: {},
  hide: {
    minHeight: 0,
    height: 0,
    opacity: 0,
    afterChildren: true,
    delay: 200,
    staggerChildren: 150
  }
})

export const FirstStep = styled(PosedFirstStep)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 700px;
  ${m.tablet`
    min-height: 0;
  `}
`
export const Description = styled.span`
  font-size: ${s.fs2};
  margin-top: ${s.ss4};
  line-height: 1;
  color: ${s.black};
`
export const Title = styled.h1`
  font-family: ${s.fontHeading};
  font-size: 13vw;
  line-height: 1;
  color: ${s.black};
  padding-bottom: ${s.ss3};
  ${m.tablet`
    font-size: 72px;
  `};
`

const PosedOption = posed.span({
  hide: {
    opacity: 0
  }
})

export const Option = styled(PosedOption)`
  font-family: ${s.fontFamily};
  font-weight: 900;
  font-size: ${({ smaller }) => (smaller ? '6vw' : '11vw')};
  line-height: 1;
  color: ${s.black};
  cursor: pointer;
  border-bottom: 8px solid transparent;
  :hover {
    border-color: ${s.black};
  }
  span {
    transition: opacity 400ms ease;
    ${({ disabled }) =>
      disabled &&
      css`
        pointer-events: none;
        opacity: 0.3;
      `};
  }
  ${m.tablet`
    font-size: ${({ smaller }) => (smaller ? '36px' : '48px')};
  `};
`

export const YearContainer = styled(Flex).attrs({ justifyContent: 'flex-end' })`
  background: ${s.black};
`
export const Year = styled.span`
  font-family: ${s.fontFamily};
  font-weight: 900;
  font-size: 13vw;
  text-align: right;
  line-height: 1;
  color: ${s.white};
  margin-right: -4px;
  margin-top: -12px;
  margin-bottom: -8px;
  ${m.tablet`
    font-size: 52px;
  `};
`
