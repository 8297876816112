import { Component } from 'react'
import smoothscroll from 'smoothscroll'
import * as R from 'ramda'
import { palette, breakpoints } from '../styles'
import {
  logout,
  login,
  checkToken,
  getToken,
  getUsername,
  getStoredName,
  getAppleMusicInstance,
  storeToken,
  APPLE_MUSIC
} from '../auth'
import {
  removeUsername as removeSongkickUsername,
  checkSongkickUsername,
  getSongkickUsername,
  setSongkickUsername
} from '../services/songkick'
import { EU_COUNTRIES } from '../countryData'
import {
  sortByNumberOfArtists,
  sortByNumberOfUserLikes,
  sortBySomethingForEveryone
} from '../utils/festivalUtils'
import * as localStorage from '../utils/localStorage'
import { getSortedFestivalsWithDistances } from '../utils/filter'

export const REGIONS = {
  EARTH: 'EARTH',
  NEAR: 'NEAR',
  UK: 'UK',
  EU: 'EU',
  US: 'US',
  AU: 'AU',
  REST: 'REST'
}

export const REGION_LABELS = {
  NEAR: 'NEAR ME'
}

if (navigator.geolocation) {
  delete REGIONS.REST
} else {
  delete REGIONS.NEAR
}

const REGION_FILTERS = {
  UK: 'UK',
  EU: EU_COUNTRIES,
  US: 'US',
  AU: 'Australia',
  REST: [...EU_COUNTRIES, 'UK', 'US', 'Australia']
}

export const GROUP_FILTERS = {
  SOMETHING_FOR_EVERYONE: 'somethingForEveryone',
  NUMBER_OF_ARTISTS: 'numberOfArtists',
  NUMBER_OF_LIKES: 'numberOfLikes'
}

const groupFilterToSort = {
  [GROUP_FILTERS.SOMETHING_FOR_EVERYONE]: sortBySomethingForEveryone,
  [GROUP_FILTERS.NUMBER_OF_ARTISTS]: sortByNumberOfArtists,
  [GROUP_FILTERS.NUMBER_OF_LIKES]: sortByNumberOfUserLikes
}

const LS_GROUPS_KEY = 'festivalfindergroups'

const bodyElement = document.querySelector('body')

const scrollTo = (el = bodyElement) => smoothscroll(el)

const clearLocal = () => {
  removeSongkickUsername()
  logout('spotify')
  logout('facebook')
}

const createUserColorObject = users =>
  users.reduce((acc, curr, index) => {
    return {
      ...acc,
      [curr]: palette[index]
    }
  }, {})

class AppStateProvider extends Component {
  state = {
    fetchingFestivals: false,
    showFestivals: false,
    festivals: [],
    filteredFestivals: null,
    group: [],
    meta: {},
    tellMeMore: false,
    filterHelp: false,
    filterDismissed: false,
    selectedArtists: {},
    filterByAny: true,
    filterActive: false,
    showOneMatches: false,
    regionFilter: REGIONS.EARTH,
    filtering: false,
    location: null,
    appleMusic: {
      connected: false,
      username: null,
      loggingIn: false
    },
    spotify: {
      connected: checkToken('spotify'),
      username: checkToken('spotify') && getStoredName('spotify'),
      loggingIn: false
    },
    facebook: {
      connected: checkToken('facebook'),
      username: checkToken('facebook') && getStoredName('facebook'),
      loggingIn: false
    },
    songkick: {
      connected: checkSongkickUsername(),
      username: checkSongkickUsername() ? getSongkickUsername() : ''
    },
    lastfm: {
      connected: false,
      username: ''
    },
    previousGroups: localStorage.get(LS_GROUPS_KEY, [])
  }

  async componentDidMount() {
    const { shareId, groupId } = this.props
    if (shareId) {
      this.setState({ fetchingFestivals: true })

      const { festivals } = await fetch(
        `${process.env.REACT_APP_API_URL}/share/${shareId}`
      ).then(r => r.json())

      this.setState({ festivals, fetchingFestivals: false })
    }
    if (groupId) {
      this.setState({ fetchingFestivals: true })

      const { festivals, users, ownerName } = await fetch(
        `${process.env.REACT_APP_API_URL}/group/${groupId}/results`
      ).then(r => r.json())

      this.addToPreviousGroups(groupId, ownerName)

      const usersWithColors = createUserColorObject(users)

      const mostMatches = sortBySomethingForEveryone(festivals)

      this.setState({
        festivals: mostMatches,
        groupFilter: GROUP_FILTERS.SOMETHING_FOR_EVERYONE,
        fetchingFestivals: false,
        group: usersWithColors
      })

      this.groupTimer = setInterval(async () => {
        const { group, groupFilter } = this.state
        const { festivals: newFestivals, users: newUsers } = await fetch(
          `${process.env.REACT_APP_API_URL}/group/${groupId}/results`
        ).then(r => r.json())
        if (Object.keys(group).length !== newUsers.length) {
          const newGroup = createUserColorObject(newUsers)
          this.setState({
            group: newGroup,
            festivals: groupFilterToSort[groupFilter](newFestivals)
          })
        }
      }, 15000)
    }
  }

  componentWillUnmount() {
    if (this.groupTimer) {
      clearInterval(this.groupTimer)
    }
  }

  componentDidCatch(e) {
    this.setState({
      fetchError: true
    })
    clearLocal()
  }

  addToPreviousGroups = (id, ownerName) => {
    const { previousGroups } = this.state
    const newArray = R.uniqBy(R.prop('id'), [
      ...previousGroups,
      { id, ownerName }
    ])
    localStorage.set(LS_GROUPS_KEY, newArray)
    this.setState({ previousGroups: newArray })
  }

  setGroupFilter = groupFilter =>
    this.setState({
      groupFilter,
      festivals: groupFilterToSort[groupFilter](this.state.festivals)
    })

  toggleShowOneMatches = () =>
    this.setState({ showOneMatches: !this.state.showOneMatches })

  toggleTellMeMore = () => this.setState({ tellMeMore: !this.state.tellMeMore })

  toggleFilterHelp = () => this.setState({ filterHelp: !this.state.filterHelp })

  toggleFilterDismissed = () =>
    this.setState({ filterDismissed: !this.state.filterDismissed })

  toggleFilterType = toState => {
    this.setState({ filterByAny: toState }, () => {
      if (this.state.filterActive) {
        this.filter()
      }
    })
  }

  setUserLocation = position => {
    this.setState(
      {
        regionFilter: REGIONS.NEAR,
        location: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      },
      this.filter
    )
  }

  selectRegionFilter = regionFilter => {
    if (regionFilter === REGIONS.NEAR) {
      navigator.geolocation.getCurrentPosition(this.setUserLocation)
      return
    }
    this.setState({ regionFilter }, () => this.filter())
  }

  filterByArtist = selectedArtist => {
    this.setState({ selectedArtist, filterActive: true }, () => {
      this.filter()
    })
  }

  filterByUser = selectedUser => {
    this.setState({ selectedUser, filterActive: true }, () => {
      this.filter()
    })
  }

  clearFilter = () => {
    this.setState(
      { selectedArtist: null, selectedUser: null, filterActive: false },
      () => {
        this.filter()
      }
    )
  }

  selectArtist = artist => {
    const { selectedArtists, filterActive } = this.state
    const artistExists = selectedArtists[artist]
    if (artistExists) {
      const { [artist]: value, ...newState } = selectedArtists
      return this.setState({ selectedArtists: newState }, () => {
        if (filterActive) {
          this.filter()
        }
      })
    }

    const newState = {
      ...selectedArtists,
      [artist]: palette[Object.keys(selectedArtists).length % palette.length]
    }
    this.setState({ selectedArtists: newState }, () => {
      if (filterActive) {
        this.filter()
      }
    })
  }

  toggleFilterActive = () => {
    const isActive = !this.state.filterActive

    if (window.innerWidth < breakpoints.tablet) {
      const regionElement = document.querySelector('#locations')
      scrollTo(regionElement)
    }
    this.setState({ filterActive: isActive }, () => {
      this.filter()
    })
  }

  filter = async () => {
    const {
      festivals,
      selectedArtist,
      selectedUser,
      filterByAny,
      regionFilter,
      filterActive,
      location
    } = this.state

    this.setState({ filtering: true })
    const regionFilteredFestivals =
      regionFilter === REGIONS.EARTH || regionFilter === REGIONS.NEAR
        ? festivals
        : festivals.reduce((result, festival) => {
            const filter = REGION_FILTERS[regionFilter]
            if (regionFilter === REGIONS.EU) {
              for (const country of REGION_FILTERS.EU) {
                if (festival.country.includes(country)) {
                  result.push(festival)
                  return result
                }
              }
            }
            if (regionFilter === REGIONS.REST) {
              for (const country of REGION_FILTERS.REST) {
                if (festival.country.includes(country)) {
                  return result
                }
              }
              result.push(festival)
              return result
            }

            if (festival.country.includes(filter)) {
              result.push(festival)
              return result
            }
            return result
          }, [])
    let filteredFestivals =
      !filterActive || !selectedArtist
        ? regionFilteredFestivals
        : regionFilteredFestivals.reduce((result, festival) => {
            if (filterByAny) {
              if (festival.artists.indexOf(selectedArtist) !== -1) {
                result.push(festival)
                return result
              }

              return result
            } else {
              if (festival.artists.indexOf(selectedArtist) === -1) {
                return result
              }

              result.push(festival)
              return result
            }
          }, [])

    if (regionFilter === REGIONS.NEAR) {
      if (
        this.memoizedDistance &&
        this.memoizedDistance.festivals.length === filteredFestivals.length
      ) {
        filteredFestivals = this.memoizedDistance.festivals
      } else {
        const festivalsByDistance = getSortedFestivalsWithDistances({
          festivals: filteredFestivals,
          userLat: location.lat,
          userLng: location.lng
        })

        this.memoizedDistance = {
          festivals: festivalsByDistance
        }
        filteredFestivals = festivalsByDistance
      }
    }

    this.setState({ filteredFestivals, filtering: false })
  }

  onCreateGroupId = (groupId, ownerName) => {
    this.addToPreviousGroups(groupId, ownerName)
    this.setState({
      createdGroupId: groupId
    })
  }

  confirmUsername = (callback = this.go) => (platform, username) => {
    if (platform === 'songkick') {
      setSongkickUsername(username)
    }
    this.setState(
      {
        [platform]: {
          ...this.state[platform],
          username,
          connected: true
        }
      },
      callback
    )
  }

  disconnect = service => {
    logout(service)
    this.setState({
      [service]: {
        ...this.state[service],
        connected: false
      }
    })
  }

  loginWithAppleMusic = (callback = this.go) => async () => {
    this.setState({
      appleMusic: {
        connected: false,
        loggingIn: true
      }
    })

    const appleMusic = await getAppleMusicInstance()

    return appleMusic.authorize().then(token => {
      storeToken(APPLE_MUSIC, token)
      this.setState({
        appleMusic: {
          connected: true,
          loggingIn: false
        }
      })
      callback()
    })
  }

  login = (service, callback = this.go) => {
    this.setState({
      [service]: {
        connected: false,
        loggingIn: true
      }
    })

    login(service)
      .then(() => getUsername(service))
      .then(username => {
        this.setState({
          [service]: {
            username,
            connected: true,
            loggingIn: false
          }
        })
        callback()
      })
  }

  loginWithFacebook = () => this.login('facebook')
  loginWithSpotify = callback => () => this.login('spotify', callback)

  disconnectFacebook = () => this.disconnect('facebook')
  disconnectSpotify = () => this.disconnect('spotify')

  disconnectSongkick = () => {
    removeSongkickUsername()
    this.setState({
      songkick: {
        connected: false,
        username: ''
      }
    })
  }

  reset = () =>
    this.setState({
      showFestivals: false
    })

  getProviderTokens = () => {
    const { spotify, facebook, songkick, appleMusic, lastfm } = this.state
    const object = {}
    if (appleMusic.connected) {
      object.appleMusic = getToken(APPLE_MUSIC)
    }
    if (spotify.connected) {
      object.spotify = getToken('spotify')
    }
    if (facebook.connected) {
      object.facebook = getToken('facebook')
    }
    if (songkick.connected) {
      object.songkick = songkick.username
    }
    if (lastfm.connected) {
      object.lastfm = lastfm.username
    }

    return object
  }

  go = () => {
    scrollTo()
    this.setState({
      fetchingFestivals: true,
      showFestivals: true
    })

    // return

    const body = {
      ...this.getProviderTokens(),
      appleMusicDeveloperToken: process.env.REACT_APP_APPLE_DEVELOPER_TOKEN
    }

    fetch(`${process.env.REACT_APP_API_URL}/festivals`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(r => r.json())
      .then(({ festivals, meta, error }) => {
        if (error) {
          clearLocal()
          this.setState({
            fetchError: true
          })
          return
        }

        const sorted = festivals.sort(
          (a, b) => b.artists.length - a.artists.length
        )
        this.setState({
          fetchingFestivals: false,
          festivals: sorted,
          meta
        })
      })
      .catch(e => {
        clearLocal()
        this.setState({
          fetchError: true
        })
      })
  }

  render() {
    const { children } = this.props
    const {
      tellMeMore,
      songkick,
      facebook,
      spotify,
      fetchingFestivals,
      showFestivals,
      festivals,
      filteredFestivals,
      selectedArtists,
      filterByAny,
      filterActive,
      filterHelp,
      meta,
      showOneMatches,
      regionFilter,
      filterDismissed,
      fetchError,
      group,
      groupFilter,
      appleMusic,
      createdGroupId,
      previousGroups,
      filtering
    } = this.state

    const displayingFestivals = showFestivals && !fetchingFestivals
    const canCompare =
      facebook.connected || spotify.connected || songkick.connected

    return children({
      group,
      tellMeMore,
      songkick,
      facebook,
      appleMusic,
      spotify,
      fetchingFestivals,
      showFestivals,
      festivals,
      filteredFestivals,
      selectedArtists,
      filterByAny,
      filterActive,
      filterHelp,
      meta,
      showOneMatches,
      regionFilter,
      filterDismissed,
      fetchError,
      displayingFestivals,
      canCompare,
      groupFilter,
      createdGroupId,
      previousGroups,
      filtering,
      addToPreviousGroups: this.addToPreviousGroups,
      onCreateGroupId: this.onCreateGroupId,
      getProviderTokens: this.getProviderTokens,
      setGroupFilter: this.setGroupFilter,
      toggleTellMeMore: this.toggleTellMeMore,
      toggleSongkickModal: this.toggleSongkickModal,
      updateUsername: this.updateUsername,
      confirmUsername: this.confirmUsername,
      toggleFilterHelp: this.toggleFilterHelp,
      loginWithAppleMusic: this.loginWithAppleMusic,
      loginWithSpotify: this.loginWithSpotify,
      disconnectSpotify: this.disconnectSpotify,
      loginWithFacebook: this.loginWithFacebook,
      disconnectFacebook: this.disconnectFacebook,
      disconnectSongkick: this.disconnectSongkick,
      go: this.go,
      selectRegionFilter: this.selectRegionFilter,
      reset: this.reset,
      selectArtist: this.selectArtist,
      toggleFilterActive: this.toggleFilterActive,
      toggleFilterType: this.toggleFilterType,
      toggleShowOneMatches: this.toggleShowOneMatches,
      toggleFilterDismissed: this.toggleFilterDismissed
    })
  }
}

export default AppStateProvider
