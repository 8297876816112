import React, { Fragment } from 'react'
import { Flex } from '@rebass/grid'

import Modal, { ModalTitle } from './layout/Modal'
import Button from './Button'
import Input from './layout/Input'
import { InputProvider } from './InputProvider'
import OpenCloseState from './OpenCloseState'

export const UsernameModal = ({
  platform,
  displayPlatform,
  confirmUsername = () => {},
  toggle,
  ...props
}) => (
  <InputProvider>
    {({ value: username, handleChange }) => (
      <Modal {...props} toggle={toggle} style={{ textAlign: 'center' }}>
        <Flex flexDirection="column" alignItems="center">
          <ModalTitle>Connect with {displayPlatform}</ModalTitle>
          <p>All you need to do is enter your {displayPlatform} Username</p>
          {username.includes('@') && <p>Not your email!</p>}
          <Flex mt={3} />
          <Input
            type="text"
            value={username}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                confirmUsername(platform, username)
                toggle()
              }
            }}
            onChange={handleChange}
          />
          <Flex mt={2} />
          <Button
            disabled={username.length < 2}
            color="black"
            onClick={() => {
              confirmUsername(platform, username)
              toggle()
            }}
          >
            Confirm Username
          </Button>
        </Flex>
      </Modal>
    )}
  </InputProvider>
)

export const UsernameModalWithState = ({
  children,
  platform,
  displayPlatform,
  confirmUsername
}) => (
  <OpenCloseState>
    {({ isOpen, toggle }) => (
      <Fragment>
        {children({ toggle })}
        <UsernameModal
          active={isOpen}
          toggle={toggle}
          platform={platform}
          displayPlatform={displayPlatform}
          confirmUsername={confirmUsername}
        />
      </Fragment>
    )}
  </OpenCloseState>
)

export default UsernameModal
