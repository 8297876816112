import React, { Fragment } from 'react'
import styled, { keyframes, css } from 'styled-components'
import moment from 'moment'
import { FaTrophy } from 'react-icons/fa'
import { Flex } from '@rebass/grid'

import { letterSpacing, selectors as s, media as m } from '../styles'
import {
  userLikesForFestival,
  usersLikedForFestival
} from '../utils/festivalUtils'

const animateFrom = `
  transform: translateY(100%);
  opacity: 0;
`

const FestivalEnter = keyframes`
  from {
    ${animateFrom};
  } to {
    transform: translateY(0%);
    opacity: 1;
  }
`

const FestivalBox = styled.div`
  ${animateFrom};
  animation: ${FestivalEnter} 400ms forwards;
  padding: ${s.ss3};
  width: 100%;
  ${m.desktop`
    width: ${props => props.width};
  `};
`

const Rank = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  ${letterSpacing};
  display: flex;
  align-items: flex-end;
  margin-right: ${s.ss2};
  span {
    margin-right: ${s.ss1};
    line-height: 12px;
    font-family: ${s.fontHeading};
  }
  margin-bottom: ${s.ss3};
  ${m.tablet`
    margin-bottom: 0;
  `}
`

const FestivalWrapper = styled.div`
  color: ${s.primary};
  padding: ${s.ss2} ${s.ss3};
  height: 100%;
  border: 3px solid ${s.grayLightest};
  font-size: 1.1em;

  h3 {
    margin-bottom: 1rem;
    font-family: ${s.fontHeading};
    font-weight: bold;
    color: ${s.black};
    a {
      &:first-child {
        border-bottom: 0.2em solid ${s.secondaryAccent};
      }
    }
  }

  ${props =>
    props.first &&
    css`
      ${m.tablet`
        font-size: 1.6em;
      `};
      ${Rank} {
        color: goldenrod;
      }
    `};
  ${props =>
    props.second &&
    css`
      ${m.tablet`
        font-size: 1.4em;
      `};
      ${Rank} {
        color: silver;
      }
    `};
  ${props =>
    props.third &&
    css`
      ${m.tablet`
        font-size: 1.4em;
      `};
      ${Rank} {
        color: peru;
      }
    `};
`

export const Artist = styled.span`
  position: relative;
  color: ${props => (props.color ? s.white : s.primary)};
  font-size: 0.85em;
  display: inline-block;
  background: ${props => props.color || s.secondaryAccent};
  padding: ${s.ss1} ${s.ss2};
  margin: 0 ${s.ss2}
    ${({ isGroup, theme: { ss2 } }) => (isGroup ? '21px' : ss2)} 0;
  border-radius: ${s.rrSmall};
  cursor: pointer;
  transition: 200ms;
  &:hover {
    transform: translateY(-3px);
  }
`

export const LikedByContainer = styled.span`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 8px;
  display: flex;
  justify-content: center;
`
export const LikedByDot = styled.span`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin: ${s.ss1};
`

const ResultInfo = styled.div`
  display: flex;
  line-height: 1;
  align-items: center;
  margin: ${s.ss2} 0;
  justify-content: center;
  flex-direction: column;
  ${m.tablet`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`
const Matches = styled.span`
  border-radius: 4px;
  color: ${s.grayDark};
  font-size: 0.7em;
  font-weight: normal;
`

const DateLocation = styled.div`
  display: flex;
  margin-bottom: ${s.ss3};
  font-size: 0.7em;
  color: ${s.grayDark};
  justify-content: center;
  align-items: center;
  ${m.tablet`
    justify-content: space-between;
  `}
`

const FestivalTitleRow = styled.h3`
  display: flex;
  justify-content: center;
  text-align: center;
  ${m.tablet`
    text-align: left;
    justify-content: space-between;
  `}
`
const ArtistContainer = styled.div`
  text-align: center;
  ${m.tablet`
    text-align: left;
  `}
`
const Link = styled.a`
  border-bottom: 3px solid ${s.secondaryAccent};
  color: ${s.grayDark};
  font-size: 0.8em;
  white-space: nowrap;
  margin-left: ${s.ss2};
`

export const Festival = ({
  festival,
  rank,
  width = '33.333%',
  selectArtist,
  selectedArtists,
  group,
  ...props
}) => {
  const momentDate = moment(festival.startDate)
  const dateFromNow = moment(festival.startDate).fromNow()
  const usersLiked = group && usersLikedForFestival(festival)
  const allUsersLiked = usersLiked && usersLiked === Object.keys(group).length
  const groupHasUsers = group && Object.keys(group).length > 1

  const artists = group
    ? festival.artists.sort((a, b) => b.users.length - a.users.length)
    : festival.artists
  return (
    <FestivalBox width={width} {...props}>
      <FestivalWrapper {...props}>
        <ResultInfo>
          <Rank>
            <span>{rank}</span>
            {props.first && <FaTrophy />}
            {(props.second || props.third) && (
              <FaTrophy size={10} />
            )}
          </Rank>
          <Flex alignItems="center">
            <Matches>
              <span>{festival.artists.length}</span>{' '}
              {groupHasUsers ? 'artists' : 'matches'}
            </Matches>
            {festival.distance && (
              <Fragment>
                <Flex mx={1}>•</Flex>
                <Matches>
                  <span>{Math.ceil(festival.distance)}</span> km away
                </Matches>
              </Fragment>
            )}
            {groupHasUsers && (
              <Fragment>
                <Flex mx={1}>•</Flex>
                <Matches>
                  <span>{userLikesForFestival(festival)}</span> likes
                </Matches>
                <Flex mx={1}>•</Flex>
                <Matches>
                  <span>
                    something for {allUsersLiked ? 'all' : usersLiked}
                  </span>{' '}
                  of you
                </Matches>
              </Fragment>
            )}
          </Flex>
        </ResultInfo>
        <FestivalTitleRow>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={festival.songkickUri}
          >
            {festival.name}
          </a>
        </FestivalTitleRow>
        <DateLocation>
          <span>
            {festival.city},{' '}
            {momentDate !== 'Invalid date' ? dateFromNow : null}
          </span>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`https://festivalplaylists.co/${festival.id}`}
          >
            Custom Playlist
          </Link>
        </DateLocation>
        <ArtistContainer>
          {artists.map(({ name, normalised, users }) => (
            <Artist
              key={normalised}
              onClick={() => selectArtist(name)}
              color={selectedArtists[name]}
              isGroup={groupHasUsers}
            >
              {name}
              {groupHasUsers && (
                <LikedByContainer>
                  {users.map((userName, index) => (
                    <LikedByDot
                      key={`${userName}_${index}`}
                      color={group[userName]}
                    />
                  ))}
                </LikedByContainer>
              )}
            </Artist>
          ))}
        </ArtistContainer>
      </FestivalWrapper>
    </FestivalBox>
  )
}

export default Festival
