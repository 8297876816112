import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import styled, { ThemeProvider, keyframes } from 'styled-components'

import App from './App'
import Share from '../views/Share'
import Callback from './Callback'
import Footer from './Footer'
import { GlobalStyle, theme } from '../styles'
import JoinGroup from '../views/JoinGroup'
import ViewGroup from '../views/ViewGroup'

const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  transition: 400ms ease;
  opacity: 0;
  animation: ${fadeIn} 400ms ease forwards;
`

class Root extends Component {
  componentDidCatch(error) {
    console.log(error)
  }

  render() {
    return (
      <Fragment>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Wrapper>
            <Router>
              <Switch>
                <Route exact path="/callback/:service" component={Callback} />
                <Route exact path="/s/:shareId" component={Share} />
                <Route exact path="/g/:groupId/join" component={JoinGroup} />
                <Route exact path="/g/:groupId" component={ViewGroup} />
                <Route exact path="*" component={App} />
              </Switch>
            </Router>
            <Footer landing />
          </Wrapper>
        </ThemeProvider>
      </Fragment>
    )
  }
}

export default Root
