import styled, { keyframes, css } from 'styled-components'

import { selectors as s } from '../styles'

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`

const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${s.ss2} ${s.ss3};
  border: 2px solid;
  transition: 400ms ease;
  cursor: pointer;
  background: transparent;
  color: ${({ color, theme }) => theme[color] || theme.black};
  font-size: ${s.fs4};
  font-weight: bold;
  ${({ fadeIn }) =>
    fadeIn &&
    css`
      opacity: 0;
      animation: ${fadeInAnimation} 400ms ease forwards;
    `}
  span {
    margin-left: ${s.ss2};
  }

  h5 {
    margin-left: ${s.ss2};
    font-size: ${s.fs4};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &:hover {
    background: ${({ color, theme }) => theme[color]};
    border-color: ${({ color, theme }) => theme[color]};
    color: ${({ hoverColor, theme }) => theme[hoverColor] || theme.white};
    svg {
      fill: ${({ hoverColor, theme }) => theme[hoverColor] || theme.white};
    }
  }
`

export default Button
