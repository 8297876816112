import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { FaPlus } from 'react-icons/fa'

import { selectors as s, media as m } from '../../../styles'
import { InviteGroupModalWithState } from '../../../components/InviteGroupModal'
import { getStoredNames } from '../../../auth'

const Outer = styled.div`
  position: fixed;
  z-index: 10;
  top: ${s.ss3};
  right: 0;
  overflow-x: scroll;
  width: 100%;
  ${m.tablet`
    top: ${s.ss4};
    right: ${s.ss3};
  `};
`
const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  overflow-x: scroll;
  min-width: 100%;
  padding-left: ${s.ss4};
  padding-right: ${s.ss3};
`

const User = styled.div`
  cursor: pointer;
  text-align: center;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: ${s.ss3};
  padding: ${s.ss2};
  color: ${s.offWhite};
  word-break: break-word;
`

const userHasJoinedGroup = group => {
  const names = Object.keys(group)
  const userNames = Object.values(getStoredNames())

  return names.some(name => userNames.includes(name))
}

const getInitials = name => {
  if (!name) return ''
  const split = name.split(' ')
  if (split.length === 1) {
    return split[0].charAt(0)
  }
  return split[0].charAt(0) + split[split.length - 1].charAt(0)
}

const Group = ({ group, id }) => (
  <Outer>
    <Container>
      {Object.keys(group).map(user => (
        <Fragment>
          <User key={user} data-tip data-for={user} color={group[user]}>
            {getInitials(user).toUpperCase()}
          </User>
          <ReactTooltip id={user} place="bottom" effect="solid">
            {user}
          </ReactTooltip>
        </Fragment>
      ))}
      {userHasJoinedGroup(group) ? (
        <InviteGroupModalWithState>
          {({ toggle }) => (
            <Fragment>
              <User
                data-tip
                data-for="inviteUser"
                onClick={toggle}
                style={{
                  transform: 'scale(0.8)',
                  transformOrigin: 'center'
                }}
                color="black"
              >
                <FaPlus size={12} />
              </User>
              <ReactTooltip id="inviteUser" place="bottom" effect="solid">
                Invite
              </ReactTooltip>
            </Fragment>
          )}
        </InviteGroupModalWithState>
      ) : (
        <Link to={`/g/${id}/join`}>
          <User
            data-tip
            data-for="joinGroup"
            style={{
              transform: 'scale(0.8)',
              transformOrigin: 'center'
            }}
            color="black"
          >
            <FaPlus size={12} />
          </User>
          <ReactTooltip id="joinGroup" place="bottom" effect="solid">
            Join this group
          </ReactTooltip>
        </Link>
      )}
    </Container>
  </Outer>
)

export default Group
