export const color = {
  white: '#fefefe',
  offWhite: 'whitesmoke',
  black: '#000',
  primary: 'rgb(100, 115, 156)',
  secondary: 'rgb(125, 185, 232)',
  primaryAccent: '#d3ecff',
  secondaryAccent: '#f4f4fd',
  mid: 'rgb(94, 122, 202)',
  gray: '#524e48',
  grayLightest: '#f9f9f9',
  grayLighter: '#d9e6f7',
  grayLight: '#ccc',
  grayDark: '#343434',
  grayDarker: '#242424',
  warning: '#FFC107',
  error: 'rgb(210, 84, 44)'
}

export const palette = [
  'dodgerblue',
  'burlywood',
  'lightseagreen',
  'skyblue',
  'coral',
  'deeppink',
  'yellowgreen',
  'crimson'
]

const fontSizes = {
  fsHeadline: '40px',
  fs1: '32px',
  fs2: '24px',
  fs3: '20px',
  fs4: '16px',
  fs5: '14px',
  fs6: '12px',
  fs7: '10px',
  fs8: '8px'
}

export const font = {
  fontFamily: `nimbus-sans, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif`,
  fontHeading: `nimbus-sans-extended, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif`,
  lineHeight: '1.2',
  letterSpacing: '.02em',
  color: color.black,
  size: fontSizes.fs4
}

export const spacing = {
  ss1: '4px',
  ss2: '8px',
  ss3: '16px',
  ss4: '32px',
  ss5: '64px',
  ss6: '128px'
}

const shadows = {
  shadowLight: '0 0 11px 0 rgba(0, 0, 0, 0.07)',
  shadowMedium: '0px 1px 9px rgba(0, 0, 0, 0.11)',
  shadowHeavy: '0 2px 8px 0 rgba(0, 0, 0, 0.2)'
}

const radii = {
  rrSmall: '6px',
  rrMedium: '12px',
  rr100: '100%',
  rrCircle: '50%'
}

export const breakpoints = {
  phone: '320px',
  tablet: '768px',
  desktop: '1024px',
  xlarge: '1600px'
}

export const zIndexes = {
  z1: 1,
  z2: 2,
  z3: 3,
  z4: 4,
  z5: 5,
  zMax: 9999
}

export const theme = {
  ...color,
  ...font,
  ...fontSizes,
  ...spacing,
  ...shadows,
  ...breakpoints,
  ...zIndexes,
  ...radii
}

export default theme
