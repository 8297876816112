const CLIENT_ID = '941512575984132'
const REDIRECT_URI =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/callback/facebook'
    : 'https://bestfestivalfor.me/callback/facebook'

const scope = ['user_likes']

export const LOGIN_URL = `https://www.facebook.com/v2.11/dialog/oauth?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${encodeURIComponent(
  REDIRECT_URI
)}&scope=${encodeURIComponent(scope.join(','))}`

export const getUsername = token =>
  fetch(`https://graph.facebook.com/v2.11/me?access_token=${token}`)
    .then(r => r.json())
    .then(r => r.name)
