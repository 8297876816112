import React from 'react'
import styled from 'styled-components'
import qs from 'query-string'
import { selectors as s } from '../styles'
import { storeToken } from '../auth'

const LoggingIn = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: ${s.white};
`

export default ({ match = {}, history }) => {
  const hash = window.location.hash
  const hasToken = hash.includes('access_token')

  const parsed = qs.parse(hash)
  const key = match.params.service

  if (hasToken && key) {
    const { access_token: token, expires_in: expiry, state } = parsed
    window.addEventListener('message', event => {
      const message = event.data
      if (message === 'login') {
        event.source.postMessage(
          JSON.stringify({
            key,
            token,
            expiry
          }),
          event.origin
        )
        window.close()
      }
    })
    window.setTimeout(() => {
      storeToken(key, token, expiry)
      window.close()
    }, 1500)
    window.setTimeout(() => {
      history.replace(decodeURIComponent(state), { go: true })
    }, 1600)
  }
  return <LoggingIn>Logging in...</LoggingIn>
}
