import React, { Fragment } from 'react'
import { Flex } from '@rebass/grid'

import Festival from '../Festival'
import Meta from '../Meta'
import FestivalGrid from '../FestivalGrid'
import Button from '../Button'

import {
  ShowMore,
  Wrapper,
  Container,
  NoFestivals,
  MetaContainer
} from './style'

const getErrorMessage = ({ meta, filterActive, regionFilter }) => {
  const usingRegionFilter = regionFilter !== 'EARTH'
  if (filterActive) {
    return `Try changing your filter${
      usingRegionFilter ? ' or region' : ''
    } options.`
  }
  if (usingRegionFilter) {
    return 'Try choosing a different region.'
  }
  if (meta && meta.uniqueArtists < 15) {
    if (meta.uniqueArtists === 0) {
      return 'Ensure that you entered the correct credentials, no artists were found.'
    }
    return 'Try connecting with more services.'
  }
  return 'Something may have gone wrong.'
}

const Festivals = ({
  children,
  showFestivals,
  displayingFestivals,
  fetchingFestivals,
  festivals = [],
  selectedArtists = [],
  meta,
  showOneMatches = false,
  toggleShowOneMatches = () => {},
  selectRegionFilter = () => {},
  regionFilter,
  filterActive,
  selectArtist,
  landing,
  group,
  midContentCta,
  filtering
}) => {
  const [first, second, third, ...rest] = festivals
  const moreThanOneMatches = rest.filter(fest => fest.artists.length > 1)
  const oneMatches = rest.filter(fest => fest.artists.length === 1)

  const festivalsToDisplay =
    showOneMatches || festivals.length <= 20 ? rest : moreThanOneMatches

  const festivalProps = {
    selectArtist,
    selectedArtists,
    group
  }

  return (
    <Wrapper>
      {landing}
      <Container pose={displayingFestivals ? 'displaying' : 'hidden'}>
        {displayingFestivals && (
          <Fragment>
            <MetaContainer>
              {children}
              <Meta
                festivals={festivals}
                totalFestivals={festivals.length}
                meta={meta}
                regionFilter={regionFilter}
                filtering={filtering}
                selectRegionFilter={selectRegionFilter}
              />
            </MetaContainer>
            {!first && festivalsToDisplay.length === 0 && (
              <NoFestivals>
                <h3>No festivals to display</h3>
                <p>{getErrorMessage({ meta, filterActive, regionFilter })}</p>
              </NoFestivals>
            )}
            {first && (
              <Festival
                {...festivalProps}
                first
                key={first.songkickId}
                rank="Your Best Festival"
                festival={first}
                style={{
                  flex: 1,
                  flexBasis: '50%',
                  animationDelay: `${500}ms`
                }}
              />
            )}
            {second && (
              <Festival
                {...festivalProps}
                second
                key={second.songkickId}
                width="50%"
                rank="#2"
                festival={second}
                selectArtist={selectArtist}
                style={{
                  animationDelay: `${600}ms`
                }}
              />
            )}
            {third && (
              <Festival
                {...festivalProps}
                third
                key={third.songkickId}
                width="50%"
                rank="#3"
                festival={third}
                selectArtist={selectArtist}
                style={{
                  animationDelay: `${700}ms`
                }}
              />
            )}
            {midContentCta}
            <FestivalGrid
              festivalProps={festivalProps}
              festivals={festivalsToDisplay}
            />
            {oneMatches.length > 0 && festivals.length > 20 && !showOneMatches && (
              <ShowMore>
                There are {oneMatches.length} more festivals that only match one
                artist.
                <Flex mt={3} />
                <Button color="black" onClick={toggleShowOneMatches}>
                  <h5>Show All Matches</h5>
                </Button>
              </ShowMore>
            )}
          </Fragment>
        )}
      </Container>
    </Wrapper>
  )
}

export default Festivals
