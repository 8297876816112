import styled from 'styled-components'
import { selectors as s } from '../../styles'

const Input = styled.input`
  font-size: ${s.fs4};
  padding: ${s.ss2};
  width: 100%;
  background: ${s.offWhite};
`

export const InputLabel = styled.label`
  font-weight: bold;
  margin-bottom: ${s.ss1};
`
export const InputInfo = styled.span`
  font-size: ${s.fs6};
  color: ${s.grayLight};
  margin-top: ${s.ss1};
`

export default Input
