import React, { Fragment } from 'react'

import AppStateProvider from '../../components/AppStateProvider'
import Festivals from '../../components/Festivals'
import Landing from '../../components/Landing'
import { Loading } from '../../components/Loading'

import Group from './components/Group'
import GroupFilter from './components/GroupFilter'
import Newsletter from './components/Newsletter'

const ViewGroup = ({ match }) => (
  <AppStateProvider groupId={match.params.groupId}>
    {({
      fetchingFestivals,
      displayingFestivals,
      festivals,
      filteredFestivals,
      selectedArtists,
      filterByAny,
      filterActive,
      filterHelp,
      showOneMatches,
      regionFilter,
      filterDismissed,
      toggleFilterHelp,
      selectRegionFilter,
      reset,
      selectArtist,
      toggleFilterActive,
      toggleFilterType,
      toggleShowOneMatches,
      toggleFilterDismissed,
      group,
      groupFilter,
      setGroupFilter
    }) => (
      <Fragment>
        {fetchingFestivals || !festivals ? (
          <Loading />
        ) : (
          <Fragment>
            <Group id={match.params.groupId} group={group} />
            <Festivals
              displayingFestivals
              group={group}
              selectRegionFilter={selectRegionFilter}
              regionFilter={regionFilter}
              reset={reset}
              festivals={filteredFestivals || festivals}
              selectArtist={selectArtist}
              selectedArtists={selectedArtists}
              toggleFilterActive={toggleFilterActive}
              filterByAny={filterByAny}
              filterActive={filterActive}
              toggleFilterType={toggleFilterType}
              showOneMatches={showOneMatches}
              toggleShowOneMatches={toggleShowOneMatches}
              toggleFilterHelp={toggleFilterHelp}
              filterHelp={filterHelp}
              toggleFilterDismissed={toggleFilterDismissed}
              filterDismissed={filterDismissed}
              fetchingFestivals={fetchingFestivals}
              showFestivals
              landing={<Landing showFestivals displayingFestivals titleOnly />}
              midContentCta={<Newsletter />}
            >
              <GroupFilter
                currentGroupFilter={groupFilter}
                setGroupFilter={setGroupFilter}
              />
            </Festivals>
          </Fragment>
        )}
      </Fragment>
    )}
  </AppStateProvider>
)

export default ViewGroup
