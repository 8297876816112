import React from 'react'
import styled from 'styled-components'
import { FaGlobe, FaMapPin } from 'react-icons/fa'

import { selectors as s } from '../styles'
import { REGIONS, REGION_LABELS } from './AppStateProvider'
import { SelectablePill } from './SelectablePill'

const Title = styled.h3`
  margin-top: ${s.ss3};
  margin-bottom: ${s.ss2};
  font-family: ${s.fontHeading};
`

const LocationPicker = styled.div`
  div {
    display: flex;
    flex-wrap: wrap;
  }
`

const RegionPicker = ({
  regionFilter = REGIONS.EARTH,
  selectRegionFilter = () => {},
  filtering
}) =>
  console.log({ filtering }) || (
    <LocationPicker id="locations">
      <Title>{filtering ? 'Filtering...' : 'Region Filter'}</Title>
      <div style={filtering ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
        {Object.keys(REGIONS).map(key => (
          <SelectablePill
            key={key}
            selected={regionFilter === REGIONS[key]}
            onClick={() => selectRegionFilter(key)}
          >
            {key === 'EARTH' && (
              <FaGlobe
                size={12}
                style={{ marginRight: '0.5rem' }}
              />
            )}
            {key === REGIONS.NEAR && (
              <FaMapPin
                size={12}
                style={{ marginRight: '0.5rem' }}
              />
            )}
            {REGION_LABELS[key] || key}
          </SelectablePill>
        ))}
      </div>
    </LocationPicker>
  )

export default RegionPicker
