import React, { Fragment } from 'react'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import { Flex } from '@rebass/grid'

import { selectors as s } from '../styles'
import { ShareLinkProvider } from './ShareLinkProvider'
import Modal, { ModalTitle } from './layout/Modal'
import Button from './Button'

import OpenCloseState from './OpenCloseState'
import { Loading } from './Loading'

const LinkText = styled.span`
  margin-bottom: ${s.ss2};
  padding: ${s.ss3};
  border-radius: 5px;
  background-color: ${s.grayLightest};
  font-family: monospace;
  word-break: break-word;
`

const ShareModal = ({ festivals, active, ...props }) => (
  <ShareLinkProvider fetch={active} festivals={festivals}>
    {({ loading, link }) => (
      <Modal {...props} active={active} style={{ textAlign: 'center' }}>
        <ModalTitle>Share Your Results</ModalTitle>
        <p>Post or send the link below to friends to share your results</p>
        {loading ? (
          <Loading />
        ) : (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={3}
          >
            <LinkText>{link}</LinkText>
            <OpenCloseState>
              {({ isOpen, toggle }) => (
                <Button
                  color="black"
                  onClick={
                    isOpen
                      ? null
                      : () => {
                          copy(link)
                          toggle()
                        }
                  }
                >
                  {isOpen ? 'Copied!' : 'Click to copy'}
                </Button>
              )}
            </OpenCloseState>
          </Flex>
        )}
      </Modal>
    )}
  </ShareLinkProvider>
)

export const ShareModalWithState = ({ children, festivals }) => (
  <OpenCloseState>
    {({ isOpen, toggle }) => (
      <Fragment>
        {children({ toggle })}
        <ShareModal festivals={festivals} active={isOpen} toggle={toggle} />
      </Fragment>
    )}
  </OpenCloseState>
)

export default ShareModal
