import React, { Component } from 'react'

class AsyncValueProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      value: null,
      loading: this.props.getOnMount
    }
  }

  componentDidMount() {
    if (this.props.getOnMount) {
      this.getAsyncValue()
    }
  }

  getAsyncValue = async options => {
    const { getValue, after } = this.props
    this.setState({ loading: true })
    try {
      const value = await getValue(options)
      if (value.error) {
        return this.setState({ error: value.error, loading: false })
      }
      if (after) {
        after({ value })
      }
      this.setState({ value, loading: false })
    } catch (e) {
      this.setState({ error: e, loading: false })
    }
  }

  render() {
    const { value, loading, error } = this.state
    const { children } = this.props

    return children({ value, error, loading, get: this.getAsyncValue })
  }
}
AsyncValueProvider.defaultProps = {
  getOnMount: true
}

export const FetchProvider = ({
  url,
  options,
  getOnMount,
  children,
  after
}) => (
  <AsyncValueProvider
    getOnMount={getOnMount}
    getValue={() =>
      fetch(`${process.env.REACT_APP_API_URL}${url}`, options).then(r =>
        r.json()
      )
    }
    after={after}
  >
    {({ value, loading, get, error }) =>
      children({ value, error, loading, get })
    }
  </AsyncValueProvider>
)

export default AsyncValueProvider
