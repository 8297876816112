import { Component } from 'react'

class OpenCloseState extends Component {
  state = { isOpen: false }
  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  render() {
    const { children } = this.props
    const { isOpen } = this.state
    return children({
      isOpen,
      toggle: this.toggle
    })
  }
}

export default OpenCloseState
