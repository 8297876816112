import React from 'react'
import styled, { css } from 'styled-components'

import { selectors as s, media as m } from '../styles'

const FooterWrapper = styled.div`
  font-size: ${s.fs4};
  display: flex;
  width: 100%;
  margin: auto 0 ${s.ss4};
  color: ${s.grayLight};
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  span {
    margin: 0 ${s.ss2};
  }
  a {
    font-weight: 900;
  }
  padding: 0 ${s.ss3};
  ${m.tablet`
    justify-content: space-between;
    flex-direction: row;
    padding: 0 ${s.ss5};
    padding-top: ${s.ss5};
  `};
  ${props =>
    props.hide &&
    css`
      opacity: 0;
      visibility: hidden;
    `};
`

const Footer = ({ hide, landing, invert, filterActive }) => (
  <FooterWrapper
    hide={hide}
    landing={landing}
    invert={invert}
    filterActive={filterActive}
  >
    <span>Festival data via Songkick</span>
    {invert && <span>•</span>}
    <span>
      Created by{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://lawrenceholmes.co.uk/"
      >
        Lawrence Holmes
      </a>
      {' • '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/lawrhol"
      >
        Twitter
      </a>
    </span>
  </FooterWrapper>
)

export default Footer
