import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { FaHeart } from 'react-icons/fa'

import { selectors as s } from '../../../styles'
import { GROUP_FILTERS } from '../../../components/AppStateProvider'
import { SelectablePill } from '../../../components/SelectablePill'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.h3`
  margin-bottom: ${s.ss2};
  font-family: ${s.fontHeading};
`

const FILTER_TO_TYPES = {
  [GROUP_FILTERS.SOMETHING_FOR_EVERYONE]: (
    <span>
      <FaHeart size={12} style={{ marginRight: '0.5rem' }} />
      Fair
    </span>
  ),
  [GROUP_FILTERS.NUMBER_OF_ARTISTS]: 'Matches',
  [GROUP_FILTERS.NUMBER_OF_LIKES]: 'Likes'
}

const GroupFilter = ({ setGroupFilter, currentGroupFilter }) => (
  <Flex flexDirection="column" justifyContent="center">
    <Title>Group Sort</Title>
    <Container>
      {Object.entries(FILTER_TO_TYPES).map(([filterName, filterText]) => (
        <SelectablePill
          key={filterName}
          selected={filterName === currentGroupFilter}
          onClick={() => setGroupFilter(filterName)}
        >
          {filterText}
        </SelectablePill>
      ))}
    </Container>
  </Flex>
)

export default GroupFilter
