import React, { Fragment } from 'react'
import { Flex } from '@rebass/grid'
import { Link } from 'react-router-dom'

import AppStateProvider from '../components/AppStateProvider'
import Festivals from '../components/Festivals'
import Landing from '../components/Landing'
import Button from '../components/Button'

import { Loading } from '../components/Loading'
import { ModalTitle } from '../components/layout/Modal'

const Share = ({ match }) => (
  <AppStateProvider shareId={match.params.shareId}>
    {({
      fetchingFestivals,
      festivals,
      filteredFestivals,
      selectedArtists,
      filterByAny,
      filterActive,
      filterHelp,
      showOneMatches,
      regionFilter,
      filterDismissed,
      toggleFilterHelp,
      selectRegionFilter,
      reset,
      selectArtist,
      toggleFilterActive,
      toggleFilterType,
      toggleShowOneMatches,
      toggleFilterDismissed,
      filtering
    }) => (
      <Fragment>
        {fetchingFestivals || !festivals ? (
          <Loading />
        ) : (
          <Fragment>
            <Festivals
              filtering={filtering}
              displayingFestivals
              selectRegionFilter={selectRegionFilter}
              regionFilter={regionFilter}
              reset={reset}
              festivals={filteredFestivals || festivals}
              selectArtist={selectArtist}
              selectedArtists={selectedArtists}
              toggleFilterActive={toggleFilterActive}
              filterByAny={filterByAny}
              filterActive={filterActive}
              toggleFilterType={toggleFilterType}
              showOneMatches={showOneMatches}
              toggleShowOneMatches={toggleShowOneMatches}
              toggleFilterHelp={toggleFilterHelp}
              filterHelp={filterHelp}
              toggleFilterDismissed={toggleFilterDismissed}
              filterDismissed={filterDismissed}
              fetchingFestivals={fetchingFestivals}
              showFestivals
              landing={
                <Landing
                  showFestivals
                  displayingFestivals
                  titleOnly
                  extraContent={
                    <Flex
                      alignItems="center"
                      justifyContent="flex-end"
                      flexDirection="column"
                      mt={[4, 0]}
                    >
                      <ModalTitle>You're viewing shared results</ModalTitle>
                      <Link to="/">
                        <Button color="black">
                          Click here to see your results
                        </Button>
                      </Link>
                    </Flex>
                  }
                />
              }
            />
          </Fragment>
        )}
      </Fragment>
    )}
  </AppStateProvider>
)

export default Share
