import styled from 'styled-components'
import { selectors as s } from '../styles'

export const ErrorText = styled.div`
  margin: ${s.ss3} 0;
  padding: ${s.ss2} ${s.ss3};
  background-color: ${s.error};
  color: ${s.white};
  font-size: ${s.fs5};
`
