const CLIENT_ID = '6f0ae2defd1140208db7431165413cb5'
const REDIRECT_URI = `${window.location.origin}/callback/spotify`

const scope = ['user-follow-read', 'user-top-read', 'user-library-read']

export const LOGIN_URL = `https://accounts.spotify.com/authorize/?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${encodeURIComponent(
  REDIRECT_URI
)}&scope=${encodeURIComponent(scope.join(' '))}`

export const getUsername = token =>
  fetch(`https://api.spotify.com/v1/me`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(r => r.json())
    .then(profile => profile.display_name || profile.id)
