import { keyframes } from 'styled-components'

export const bounceScaleUp = keyframes`
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    opacity: 0;
    transform: translate3d(-50%, 3000px, 0) scale(0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-50%, -20px, 0) scale(1);
  }

  75% {
    transform: translate3d(-50%, 10px, 0);
  }

  90% {
    transform: translate3d(-50%, -5px, 0);
  }

  to {
    transform: translate3d(-50%, 0, 0);
  }
`
