import { Component } from 'react'

export class InputProvider extends Component {
  state = { value: '' }

  handleChange = e => this.setState({ value: e.target.value })

  render() {
    const { children } = this.props
    const { value } = this.state
    return children({
      value,
      handleChange: this.handleChange
    })
  }
}
