import React from 'react'
import styled from 'styled-components'
import { selectors as s } from '../styles'

const LoadingText = styled.span`
  font-family: ${s.fontHeading};
  font-size: ${s.fs3};
  letter-spacing: ${s.ls1};
`

export const Loading = () => <LoadingText>Loading</LoadingText>
