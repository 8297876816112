import React, { Fragment } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { Flex } from '@rebass/grid'

import { selectors as s } from '../styles'
import Modal from './layout/Modal'
import Button from './Button'

import OpenCloseState from './OpenCloseState'

const LinkText = styled.span`
  margin-bottom: ${s.ss2};
  padding: ${s.ss3};
  border-radius: 5px;
  background-color: ${s.grayLightest};
  font-family: monospace;
  word-break: break-word;
`

export const InviteModalContent = ({ url }) => (
  <Fragment>
    <p style={{ paddingBottom: '8px' }}>
      Just send your friends the link below to invite them to join this group
    </p>
    <p>
      Head's up - anyone with a link to your group can join it, so be careful
      not to share it too publicly!
    </p>
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pt={3}
    >
      <LinkText>{url}</LinkText>
      <OpenCloseState>
        {({ isOpen, toggle }) => (
          <Button
            hoverColor="gray"
            onClick={
              isOpen
                ? null
                : () => {
                    copy(url)
                    toggle()
                  }
            }
          >
            {isOpen ? 'Copied!' : 'Click to copy'}
          </Button>
        )}
      </OpenCloseState>
    </Flex>
  </Fragment>
)

const InviteGroupModal = withRouter(
  ({
    match: {
      params: { groupId }
    },
    ...props
  }) => {
    const url = `${window.location.origin}/g/${groupId}/join`
    return (
      <Modal {...props} style={{ textAlign: 'center' }}>
        <h3>Invite to Group</h3>
        <InviteModalContent url={url} />
      </Modal>
    )
  }
)

export const InviteGroupModalWithState = ({ children }) => (
  <OpenCloseState>
    {({ isOpen, toggle }) => (
      <Fragment>
        {children({ toggle })}
        <InviteGroupModal active={isOpen} toggle={toggle} />
      </Fragment>
    )}
  </OpenCloseState>
)

export default InviteGroupModal
