import { css } from 'styled-components'

import { breakpoints } from './theme'

const media = Object.keys(breakpoints).reduce(
  (acc, label) => ({
    [label]: (...args) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `,
    ...acc
  }),
  {}
)

export default media
