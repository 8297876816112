import t from './theme';

const createSelectorForLabel = (label) => ({ theme }) => theme[label];

const selectors = Object.keys(t).reduce(
  (acc, label) => ({
    [label]: createSelectorForLabel(label),
    ...acc,
  }),
  {},
);

export default selectors;
