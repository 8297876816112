const distanceBetween = (
  { lat: lat1, lng: lon1 },
  { lat: lat2, lng: lon2 }
) => {
  const p = 0.017453292519943295
  const c = Math.cos
  const a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2

  return 12742 * Math.asin(Math.sqrt(a))
}

export const getSortedFestivalsWithDistances = ({
  festivals,
  userLat,
  userLng
}) => {
  const festivalsWithDistances = festivals.map(
    ({ location: { latitude: festLat, longitude: festLng }, ...festival }) => {
      const distance = distanceBetween(
        { lat: festLat, lng: festLng },
        { lat: userLat, lng: userLng }
      )
      return {
        ...festival,
        distance
      }
    }
  )

  const sortedByDistance = festivalsWithDistances.sort(
    (a, b) => a.distance - b.distance
  )

  return sortedByDistance
}
