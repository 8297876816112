import React, { Component } from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import { selectors as s, media as m } from '../styles'

import RegionPicker from './RegionPicker'

const Total = styled.div`
  font-size: ${props => (props.main ? 1.5 : 1)}rem;
  ${m.tablet`
    font-size: ${props => (props.main ? 2.5 : props.artists ? 1.5 : 1.25)}rem;
  `};
  span {
    font-size: 4em;
    font-weight: bold;
  }
`

const PosedWrapper = posed.div({
  displaying: {
    opacity: 1,
    delay: 300
  },
  hidden: {
    opacity: 0
  }
})
const MetaWrapper = styled(PosedWrapper)`
  display: flex;
  flex-direction: column;
  color: ${s.black};
  width: 100%;
`
const MetaDetails = styled.div``

const Numbers = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: column;
  ${m.desktop`
    flex-direction: column;
  `};
`

class Meta extends Component {
  render() {
    const {
      totalFestivals,
      meta,
      selectRegionFilter = () => {},
      regionFilter,
      filtering,
      ...rest
    } = this.props
    const { uniqueArtists, providers } = meta || {}
    const hasMoreThanOneProvider =
      providers && Object.keys(providers).length > 1
    return (
      <MetaWrapper {...rest}>
        <RegionPicker
          filtering={filtering}
          regionFilter={regionFilter}
          selectRegionFilter={selectRegionFilter}
        />
        <Numbers>
          <Total main>
            <span>{totalFestivals}</span> festivals match
          </Total>
          {uniqueArtists && (
            <MetaDetails>
              <Total artists>
                <span>{uniqueArtists}</span>{' '}
                {hasMoreThanOneProvider ? 'unique' : 'total'} artists were
                compared
              </Total>
            </MetaDetails>
          )}
        </Numbers>
      </MetaWrapper>
    )
  }
}

export default Meta
