export const sortByNumberOfArtists = festivals =>
  festivals.sort((a, b) => b.artists.length - a.artists.length)

export const userLikesForFestival = (festival, weightByCommonArtists) =>
  festival.artists.reduce((total, { users }) => {
    total += weightByCommonArtists ? users.length * 2 : users.length
    return total
  }, 0)

export const usersLikedForFestival = festival => {
  const liked = festival.artists.reduce((usersLiked, { users }) => {
    users.forEach(user => {
      if (!usersLiked[user]) {
        usersLiked[user] = true
      }
    })
    return usersLiked
  }, {})
  return Object.keys(liked).length
}

export const sortByNumberOfUserLikes = (festivals, weightByCommonArtists) =>
  festivals.sort((a, b) => {
    const festivalLikesA = userLikesForFestival(a, weightByCommonArtists)
    const festivalLikesB = userLikesForFestival(b, weightByCommonArtists)
    return festivalLikesB - festivalLikesA
  })

export const sortBySomethingForEveryone = festivals => {
  const sortedByLikes = sortByNumberOfUserLikes(festivals, true)
  return sortedByLikes.sort((a, b) => {
    const festivalLikesA = usersLikedForFestival(a)
    const festivalLikesB = usersLikedForFestival(b)
    return festivalLikesB - festivalLikesA
  })
}
