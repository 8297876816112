import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { selectors as s } from '../../../styles'
import { MailingListForm } from '../../../components/MailSignup'

const Title = styled.h3`
  margin-bottom: ${s.ss2};
  font-family: ${s.fontHeading};
`
const Small = styled.span`
  margin-top: ${s.ss1};
  font-size: ${s.fs5};
  color: ${s.grayLight};
`

export const Container = styled(Flex).attrs({
  flexDirection: 'column'
})`
  width: 100%;
  padding: 16px;
  max-width: 700px;
  margin: 0 auto;
`

const Newsletter = () => (
  <Container>
    <Title>Get notified about updates to Festival Finder</Title>
    <MailingListForm />
    <Small>Your email is not associated with your music data in any way</Small>
  </Container>
)

export default Newsletter
