import { createGlobalStyle } from 'styled-components'
import { font, color } from './theme'

// Set box-sizing globally to handle padding and border widths
const all = `
    *,
    *:after,
    *:before {
        box-sizing: inherit;
    }
    
    * {
	    background-repeat: no-repeat;
    }
`

const html = `
    html {
        box-sizing: border-box;
        font-size: 62.5%;
        cursor: default;
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
    }
`

const body = `
    body {
        margin: 0;
        font-family: ${font.fontFamily};
        font-size: ${font.size};
        line-height: ${font.lineHeight};
        letter-spacing: ${font.letterSpacing};
        color: ${font.color};
        background: ${color.white};
        overflow-x: hidden;
    }
`

const links = `
  a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
  }
`

const typography = `
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }
`

const forms = `
  input {
    padding: 1rem;
    border:none;
    outline: none;
    font-family: ${font.fontFamily};
    font-size: ${font.size};

    &::placeholder {
      color: ${color.grayLight};
    }
  }
`

const buttons = `
  button {
    font-family: ${font.fontFamily};
    background: none;
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;
  }
`

export const GlobalStyle = createGlobalStyle`
    ${all};
    ${html};
    ${body};
    ${links};
    ${typography};
    ${forms};
    ${buttons};
`
