const LS = {
  USERNAME: 'songkickUsername'
}

export const checkSongkickUsername = () => {
  const username = localStorage.getItem(LS.USERNAME)
  return Boolean(username)
}

export const getSongkickUsername = () => {
  const username = localStorage.getItem(LS.USERNAME)
  return username
}

export const setSongkickUsername = username => {
  localStorage.setItem(LS.USERNAME, username)
}

export const removeUsername = () => {
  localStorage.removeItem(LS.USERNAME)
}
