import { Component } from 'react'

export class ShareLinkProvider extends Component {
  state = { fetchingShareLink: false }

  componentDidUpdate() {
    const { fetchingShareLink, shareId } = this.state
    if (this.props.fetch && !shareId && !fetchingShareLink) {
      this.handleShareLink()
    }
  }

  handleShareLink = () => {
    const { festivals } = this.props
    this.setState({
      fetchingShareLink: true
    })

    const body = {
      festivals
    }

    fetch(`${process.env.REACT_APP_API_URL}/create-share-link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(r => r.json())
      .then(({ shareId }) => {
        this.setState({
          fetchingShareLink: false,
          shareId
        })
      })
  }

  render() {
    const { children } = this.props
    const { fetchingShareLink, shareId } = this.state

    const link = shareId && `${window.location.origin}/s/${shareId}`

    return children({
      loading: fetchingShareLink,
      getShareLink: this.handleShareLink,
      link
    })
  }
}
