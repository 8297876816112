import React, { Component } from 'react'
import styled from 'styled-components'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import { selectors as s } from '../styles'
import Button from './Button'
import Input from './layout/Input'
import { Flex } from '@rebass/grid'

const Title = styled.h3`
  margin-bottom: ${s.ss2};
  font-family: ${s.fontHeading};
`

const MAILING_LIST =
  'https://gmail.us20.list-manage.com/subscribe/post?u=845bd8183f843050ece6dd20a&amp;id=f5492fcfae'

class Form extends Component {
  state = {
    email: ''
  }

  onSubmit = e => {
    e.preventDefault()
    this.props.onSubmitted({
      EMAIL: this.state.email
    })
  }

  handleEmailChange = e => this.setState({ email: e.target.value })

  render() {
    const { email } = this.state
    return (
      <form onSubmit={this.onSubmit}>
        <Flex
          flexDirection={['column', 'row']}
          style={{ position: 'relative' }}
        >
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            required
            onChange={this.handleEmailChange}
            value={email}
          />
          <Flex mt={[2, 0]} />
          <Button color="black" disabled={email.length === 0} type="submit">
            Submit
          </Button>
        </Flex>
      </form>
    )
  }
}

const renderForm = ({ subscribe, status, message }) => {
  if (status === 'error') {
    return <span>There was an error, {message}</span>
  }
  if (status === 'sending') {
    return <span>Sending...</span>
  }
  if (status === 'success') {
    return <span>Thanks!</span>
  }

  return <Form name="mailing_list" onSubmitted={subscribe} />
}

export const MailingListForm = () => (
  <MailchimpSubscribe url={MAILING_LIST} render={renderForm} />
)
