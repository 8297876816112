import React from 'react'
import styled, { css } from 'styled-components'

import { selectors as s } from '../../styles'

export const ModalTitle = styled.h3`
  font-family: ${s.fontHeading};
  font-size: ${s.fs3};
  margin-bottom: ${s.ss3};
`

const Wrapper = styled.div`
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.4);
  transition: 600ms;

  ${props =>
    props.active &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  background: ${s.white};
  opacity: 0;
  transition: 500ms ease;
  max-width: 600px;
  width: 90%;
  padding: 3rem;
  transform: scale(0.7);
  max-height: 70vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  color: ${s.black};
  text-align: center;
  ${props =>
    props.active &&
    css`
      transition-delay: 500ms;
      opacity: 1;
      transform: scale(1);
    `};
`

const ClickHandler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Modal = ({ children, active, toggle, ...props }) => (
  <Wrapper active={active} {...props}>
    <ClickHandler onClick={toggle} />
    <Content active={active}>{children}</Content>
  </Wrapper>
)

export default Modal
