export const set = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const get = (key, defaultValue) => {
  try {
    const value = window.localStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    } else {
      return defaultValue
    }
  } catch {
    return defaultValue
  }
}
