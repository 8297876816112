import React from 'react'
import styled from 'styled-components'

import { media as m, breakpoints } from '../styles'

import Festival from './Festival'

const Columns = styled.div`
  display: flex;
  width: 100%;
  min-height: 250px;
  flex-direction: column;
  ${m.tablet`
    flex-direction: row;
  `};
  > div {
    flex: 1;
  }
`

const generateColumns = (festivals, numColumns) => {
  const columns = Array.from(Array(numColumns)).map(() => [])
  festivals.forEach((festival, index) =>
    columns[index % numColumns].push({ ...festival, index })
  )

  return columns
}

const FestivalGrid = ({ festivals = [], festivalProps = {} }) => {
  const cols = window.innerWidth < breakpoints.tablet ? 1 : 3
  const columns = generateColumns(festivals, cols)

  return (
    <Columns>
      {columns.map((column, index) => (
        <div key={index}>
          {column.map(festival => (
            <Festival
              key={festival.songkickId}
              width="100%"
              rank={`#${festival.index + 4}`}
              festival={festival}
              style={{
                animationDelay: `${
                  festival.index < 6 ? 700 + festival.index * 100 : 0
                }ms`
              }}
              {...festivalProps}
            />
          ))}
        </div>
      ))}
    </Columns>
  )
}

export default FestivalGrid
