import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { FaShare } from 'react-icons/fa'
import { Flex } from '@rebass/grid'

import AppStateProvider from '../AppStateProvider'
import Landing from '../Landing'
import { CreateGroupModalButton } from '../CreateGroupModal'
import { ShareModalWithState } from '../ShareModal'
import { Title, Text } from '../Text'
import Festivals from '../Festivals'
import Button from '../Button'
import { Container } from '../../views/ViewGroup/components/Newsletter'

class App extends Component {
  render() {
    const { history } = this.props
    const onDismissCreateGroup = groupId => history.push(`/g/${groupId}`)
    return (
      <AppStateProvider>
        {({
          tellMeMore,
          songkick,
          facebook,
          appleMusic,
          spotify,
          fetchingFestivals,
          showFestivals,
          festivals,
          filteredFestivals,
          selectedArtists,
          filterByAny,
          filterActive,
          filterHelp,
          meta,
          showOneMatches,
          regionFilter,
          filterDismissed,
          fetchError,
          displayingFestivals,
          toggleTellMeMore,
          toggleFilterHelp,
          loginWithAppleMusic,
          loginWithSpotify,
          disconnectSpotify,
          loginWithFacebook,
          disconnectFacebook,
          disconnectSongkick,
          go,
          selectRegionFilter,
          reset,
          selectArtist,
          toggleFilterActive,
          toggleFilterType,
          toggleShowOneMatches,
          toggleFilterDismissed,
          confirmUsername,
          getProviderTokens,
          onCreateGroupId,
          createdGroupId,
          previousGroups,
          filtering
        }) => (
          <Fragment>
            <Festivals
              filtering={filtering}
              displayingFestivals={displayingFestivals}
              showFestivals={showFestivals}
              selectRegionFilter={selectRegionFilter}
              regionFilter={regionFilter}
              reset={reset}
              meta={meta}
              festivals={filteredFestivals || festivals}
              selectArtist={selectArtist}
              selectedArtists={selectedArtists}
              toggleFilterActive={toggleFilterActive}
              filterByAny={filterByAny}
              filterActive={filterActive}
              toggleFilterType={toggleFilterType}
              showOneMatches={showOneMatches}
              toggleShowOneMatches={toggleShowOneMatches}
              toggleFilterHelp={toggleFilterHelp}
              filterHelp={filterHelp}
              toggleFilterDismissed={toggleFilterDismissed}
              filterDismissed={filterDismissed}
              landing={
                <Landing
                  showFestivals={showFestivals}
                  displayingFestivals={displayingFestivals}
                  toggleTellMeMore={toggleTellMeMore}
                  loginWithAppleMusic={loginWithAppleMusic}
                  loginWithSpotify={loginWithSpotify}
                  disconnectSpotify={disconnectSpotify}
                  appleMusic={appleMusic}
                  spotify={spotify}
                  loginWithFacebook={loginWithFacebook}
                  disconnectFacebook={disconnectFacebook}
                  facebook={facebook}
                  songkick={songkick}
                  confirmUsername={confirmUsername}
                  go={go}
                  fetchingFestivals={fetchingFestivals}
                  extraContent={
                    <Fragment>
                      {!displayingFestivals && previousGroups.length > 0 && (
                        <Flex flexDirection="column">
                          <Title>Your groups</Title>
                          {previousGroups.map(({ id, ownerName }) => (
                            <Link to={`/g/${id}`}>{ownerName}'s Group</Link>
                          ))}
                        </Flex>
                      )}
                      {displayingFestivals && (
                        <Flex
                          alignItems="flex-end"
                          justifyContent="space-between"
                          mt={[4, 0]}
                        >
                          <CreateGroupModalButton
                            onDismiss={onDismissCreateGroup}
                            onCreateGroupId={onCreateGroupId}
                            createdGroupId={createdGroupId}
                            getProviderTokens={getProviderTokens}
                          />
                          <Flex ml={3} />
                          <ShareModalWithState festivals={festivals}>
                            {({ toggle }) => (
                              <Button fadeIn color="black" onClick={toggle}>
                                <FaShare />
                                <Flex mr={2} /> Share Results
                              </Button>
                            )}
                          </ShareModalWithState>
                        </Flex>
                      )}
                    </Fragment>
                  }
                />
              }
              midContentCta={
                <Container alignItems="center" justifyContent="center">
                  <Title>Compare with friends?</Title>
                  <Text>
                    You've seen your results - now invite your friends to all
                    compare together
                  </Text>
                  <Flex mt={3} />
                  <CreateGroupModalButton
                    onDismiss={onDismissCreateGroup}
                    onCreateGroupId={onCreateGroupId}
                    createdGroupId={createdGroupId}
                    getProviderTokens={getProviderTokens}
                  />
                </Container>
              }
            />
          </Fragment>
        )}
      </AppStateProvider>
    )
  }
}

export default App
