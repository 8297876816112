export const gradientBackground = ({
  abs = false,
  from = '72, 74, 179',
  to = '125, 185, 232',
  opacity = 1
}) => `
  background: ${abs ? from : `rgba(${from}, ${opacity})`};
  background: linear-gradient(135deg, ${
    abs ? from : `rgba(${from},${opacity})`
  } 0%, ${abs ? to : `rgba(${to},${opacity})`} 100%);
`

export const boxShadow = `
  box-shadow: 0 3px 10px rgba(0,0,0,0.2);
`

export const backgroundClip = `
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
`

export const letterSpacing = `
  font-size: 0.7em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
`
