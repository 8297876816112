import styled from 'styled-components'
import posed from 'react-pose'

import { selectors as s, media as m } from '../../styles'

const PosedLandingMask = posed.div({
  small: {
    width: 540,
    height: 260,
    backgroundColor: 'rgba(254, 254, 254, 0)',
    transition: {
      default: {
        delay: 500,
        type: 'spring',
        stiffness: 200,
        damping: 20,
        duration: 1000
      },
      scale: {
        type: 'spring',
        stiffness: 300,
        damping: 25,
        duration: 300,
        delay: 1500
      },
      backgroundColor: {
        delay: 2000
      }
    }
  }
})

const PADDING_PX = 30

export const LandingMask = styled(PosedLandingMask)`
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: top center;
  display: flex;
  border-radius: ${s.rrMedium};
  overflow: hidden;
  width: calc(100vw - ${PADDING_PX}px);
  height: calc(100vh - ${PADDING_PX}px);
  background-color: ${s.white};
  margin-left: ${PADDING_PX / 2}px;
  margin-top: ${PADDING_PX / 2}px;
`

export const LandingWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const PosedTitleWrapper = posed.div({
  default: {
    color: 'rgb(254, 254, 254)',
    top: 32,
    left: 32,
    y: 0,
    transition: {
      default: {
        type: 'spring',
        stiffness: 300,
        damping: 25,
        duration: 300,
        delay: 500
      },
      color: {
        delay: 2000
      }
    }
  },
  landing: {
    y: '-60%',
    top: '36%',
    left: '6%'
  }
})

export const TitleWrapper = styled(PosedTitleWrapper)`
  position: absolute;
  z-index: 1;
  margin-bottom: ${s.ss4};
  margin-top: ${s.ss4};
  display: inline-block;
  color: ${s.primary};
`

export const ShowMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  width: 100%;
`

export const Wrapper = styled.div``

const PosedContainer = posed.div({
  displaying: {
    opacity: 1
  },
  hidden: {
    opacity: 0,
    paddingTop: 0
  }
})
export const Container = styled(PosedContainer)`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${s.ss3};
  padding: 0 ${s.ss3};
  padding-top: ${s.ss3};
  ${m.tablet`
    padding: 0 ${s.ss5};
    padding-top: ${s.ss5};
  `};
`

export const MetaContainer = styled.div`
  display: flex;
  padding: ${s.ss3};
  padding-top: 0;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  ${m.tablet`
    width: auto;
  `};
`

export const NoFestivals = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8rem;
  width: 100%;
  ${m.desktop`
    width: 66.666%;
  `};
`

export const ButtonBar = styled.div`
  display: flex;
  padding: ${s.ss5} 0;
  justify-content: flex-end;
  height: 240px;
  width: 100%;
`
